<template>
  <div>
    <!--table package -->
    <ek-table
      :items="packages"
      :columns="columns"
      @details="detailsPackage"
      @delete-selected="deletePackage"
    >
      <template slot="items.dateCreated" slot-scope="{ value }">
        <span>{{ new Date(value).toLocaleDateString() }}</span>
      </template>

      <template slot="items.faculties" slot-scope="{ props }">
        <b-button :id="`popover-target-${props.row.originalIndex}`" variant="transperant">
          مرر للرؤية
        </b-button>
        <b-popover
          :target="`popover-target-${props.row.originalIndex}`"
          triggers="hover"
          placement="right"
        >
          <span>
            {{
              props.row.faculties.length > 0
                ? props.row.faculties.find((ele) => ele)
                : "لايوجد"
            }}
          </span>
        </b-popover>
      </template>

      <template slot="items.isHidden" slot-scope="{ value }">
        <StatusBadge :options="statusPackage" :value="value"></StatusBadge>
      </template>
    </ek-table>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import StatusBadge from "@/app/global/components/StatusBadge.vue";
export default {
  components: {
    StatusBadge,
  },
  computed: {
    ...mapState({
      packageList: ({ codes }) => codes.packageList,
      columns: ({ codes }) => codes.columns,
      statusPackage: ({ codes }) => codes.statusPackage,
    }),
    ...mapGetters(["packages"]),
  },
  methods: {
    detailsPackage({ row }) {
      this.$router.push(`/admin/codes/${row.id}`);
    },
    ...mapActions(["getPackageList", "deletePackage"]),
  },
  created() {
    this.getPackageList();
  },
};
</script>

<style></style>
